import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { FeatureToggle, FeatureToggleKey } from "../model/feature-toggle";
import { FeatureToggleResponse } from "../model/feature-toggle-response";
import { NotessaURLHelper } from "./notessa-url-helper.service";

@Injectable({
  providedIn: "root",
})
export class FeatureToggleService {
  constructor(private httpClient: HttpClient) {}

  private getFeatureToggle(featureToggle: FeatureToggle): Observable<any> {
    const url = NotessaURLHelper.buildFeatureToggleUrl(featureToggle);
    return this.httpClient.get<FeatureToggleResponse>(url);
  }

  public isFeatureOn(
    featureToggle: FeatureToggle,
  ): Observable<FeatureToggleResponse> {
    return this.getFeatureToggle(featureToggle).pipe(
      map((ft) => {
        let status = false;
        if (featureToggle.key === FeatureToggleKey.ACCOUNT_ID) {
          status = ft.isFeatureOn;
        } else {
          status = ft.state.toLowerCase() === "on";
        }
        return new FeatureToggleResponse(featureToggle.name, status);
      }),
      catchError(() =>
        of(new FeatureToggleResponse(featureToggle.name, false)),
      ),
    );
  }

  public isFeaturesOn(
    featureToggleList: FeatureToggle[],
  ): Observable<FeatureToggleResponse[]> {
    let observableFeaturesToggle: Observable<FeatureToggleResponse>[] = [];
    featureToggleList.forEach((name) =>
      observableFeaturesToggle.push(this.isFeatureOn(name)),
    );
    return forkJoin(observableFeaturesToggle);
  }
}
