import { Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";
import { AuthenticationService } from "../interfaces/AuthenticationService";

export const TOKEN_ID_KEY: string = "jwtAccessToken";
export const COMPANY_ID_KEY: string = "companyId";
export const COMPANY_NAME_KEY: string = "companyName";

@Injectable({
  providedIn: "root",
})
export class OktaAuthenticationService implements AuthenticationService {
  getToken(): string {
    return localStorage.getItem(TOKEN_ID_KEY);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_ID_KEY, token);
  }

  parseToken(token: string) {
    const decodedToken = this.decodeToken(token);
    if (decodedToken) {
      return {
        entityId: decodedToken.entityId,
        email: decodedToken.email,
      };
    } else {
      return null;
    }
  }

  getUser(): any {
    const user = this.parseToken(this.getToken());
    if (user) {
      return {
        user_id: user.entityId,
        user_email: user.email,
        user_name: user.email,
        company_name: localStorage.getItem("companyName"),
        company_id: localStorage.getItem("companyId"),
      };
    } else {
      return null;
    }
  }

  decodeToken(token: string): any {
    if (token) {
      try {
        return jwtDecode(token);
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  getCompanyId(): string {
    return localStorage.getItem(COMPANY_ID_KEY);
  }

  getCompanyName(): string {
    return localStorage.getItem(COMPANY_NAME_KEY);
  }
}
