/* tslint:disable:quotemark */
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AnalyticsGatewayHelper {
  buildGatewayUrl(path: string, apiVersion?: string): string {
    const env: { [key: string]: string } =
      apiVersion != undefined
        ? environment.reportsGateway[apiVersion]
        : environment.reportsGateway["V1"];
    const baseUri: string = this.buildBaseUri(env);

    return baseUri + env.version + path;
  }

  private buildBaseUri(env: { [key: string]: string }): string {
    const port: string = env.port;
    return `${location.protocol}//${buildGatewayHostName(env)}${
      port !== "" && port !== "80" ? ":" + port : ""
    }`;
  }
}

export function buildGatewayHostName(env: { [key: string]: string }): string {
  const pathname: string = location.pathname;
  const hostname: string = location.hostname;

  if (env["version"] === "/v1") {
    if (
      pathname !== undefined &&
      pathname.indexOf("notessa") > -1 &&
      hostname.indexOf("admin") > -1
    ) {
      return hostname.replace("admin", env["service"]);
    } else {
      return hostname.replace("rep-looker-front-ui", env["service"]);
    }
  } else if (env["version"] === "/v2") {
    if (pathname !== undefined && hostname.indexOf("reports.test") > -1) {
      return `${env["service"]}.poppulo-app.com.staging.lan`;
    } else if (pathname !== undefined && hostname.indexOf("reports.eu") > -1) {
      return `${env["service"]}.eu.poppulo-app.com`;
    } else if (pathname !== undefined && hostname.indexOf("reports.us") > -1) {
      return `${env["service"]}.us.poppulo-app.com`;
    } else {
      return hostname.replace("rep-looker-front-ui", env["service"]);
    }
  }
}
