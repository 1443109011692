import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { catchError, shareReplay } from "rxjs/operators";
import { AnalyticsGatewayHelper } from "./analytics-gateway-helper.service";

@Injectable({
  providedIn: "root",
})
export class LookerService {
  constructor(
    private gatewayHelper: AnalyticsGatewayHelper,
    private http: HttpClient,
  ) {}

  getDashboard(dashboardId: string, apiVersion: string): Observable<any> {
    const path: string =
      environment.reportsGateway[apiVersion].dashboardPath + dashboardId;
    const url: string = this.gatewayHelper.buildGatewayUrl(path, apiVersion);
    return this.http.get(url).pipe(
      shareReplay(1),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }
}
