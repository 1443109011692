import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LocalizationTimezoneInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addTimezoneHeader(request));
  }

  private addTimezoneHeader(request: HttpRequest<any>): HttpRequest<any> {
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone) {
      return request.clone({
        setHeaders: {
          timezone: `${timezone}`,
        },
      });
    }
    return request;
  }
}
