import { Injectable } from "@angular/core";
import { ProgressBarMode } from "@angular/material/progress-bar";

@Injectable({
  providedIn: "root",
})
export class ProgressBarHelper {
  progressBarMode: ProgressBarMode = "query";
  isVisible = true;

  setVisible(visible: boolean) {
    this.isVisible = visible;
  }
}
