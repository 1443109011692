import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Route,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { CcaAuthenticationService } from "../services/cca-authentication.service";
import { DASHBOARD_CONFIG } from "../model/dashboard-config";

@Injectable({
  providedIn: "root",
})
export class EnterpriseAccessGuard implements CanActivate {
  constructor(
    private authService: CcaAuthenticationService,
    private router: Router,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    function isEnterpriseComponent(routeConfig: Route): boolean {
      for (const { path, isEnterpriseDashboard } of DASHBOARD_CONFIG) {
        if (path === routeConfig.path) {
          return isEnterpriseDashboard;
        }
      }
      return false;
    }

    if (
      !this.authService.isEnterprise() &&
      isEnterpriseComponent(next.routeConfig)
    ) {
      return this.router.parseUrl("multichannel/overview");
    }
    return true;
  }
}
