import { Component, OnInit } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { CcaAuthenticationService } from "./services/cca-authentication.service";
import { ProgressBarHelper } from "./services/progress-bar-helper.service";
import { FeatureToggleService } from "./services/feature-toggle.service";
import { DASHBOARD_CONFIG, DashboardConfig } from "./model/dashboard-config";
import { FeatureToggle } from "./model/feature-toggle";
import { FeatureToggleResponse } from "./model/feature-toggle-response";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    public progressBarHelper: ProgressBarHelper,
    public authService: CcaAuthenticationService,
    private featureToggleService: FeatureToggleService,
  ) {}

  title = "Loading...";
  activeLink = "";
  tabs: DashboardConfig[] = [];

  ngOnInit(): void {
    const isEnterprise = this.authService.isEnterprise();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.progressBarHelper.setVisible(true);
      }
      if (event instanceof NavigationEnd) {
        this.activeLink = event.urlAfterRedirects.split(/[?#]/)[0].substring(1);
        let selectedDashboard = DASHBOARD_CONFIG.filter(
          (item) => item.path == this.activeLink,
        )[0];
        this.title = selectedDashboard.label;
        this.tabs = this.getTabs(selectedDashboard);

        if (!isEnterprise && selectedDashboard.isEnterpriseDashboard) {
          throw new Error("This dashboard is not available.");
        }
        if (this.hasFeatureToggleActivationDashboard(selectedDashboard)) {
          this.processObservableDashboards(selectedDashboard);
        }
      }
    });
  }

  private getTabs(selectedDashboard: DashboardConfig) {
    let tabs: DashboardConfig[] = [];
    let featureToggleNames: FeatureToggle[] = [];

    if (selectedDashboard.tabsGroup != null) {
      let allDashboardsInTheSameTabsGroup = DASHBOARD_CONFIG.filter(
        (item) => item.tabsGroup == selectedDashboard.tabsGroup,
      );

      for (const filteredDashboard of allDashboardsInTheSameTabsGroup) {
        if (this.hasFeatureToggleActivationDashboard(filteredDashboard)) {
          featureToggleNames.push(filteredDashboard["featureToggleActivation"]);
          continue;
        }
        tabs.push(filteredDashboard);
      }
      this.featureToggleService
        .isFeaturesOn(featureToggleNames)
        .subscribe((result) => {
          const observableDashboards = allDashboardsInTheSameTabsGroup.filter(
            (dt) => this.isObservableDashboardEnabled(dt, result),
          );
          tabs.push(...observableDashboards);
        });
    }
    return tabs;
  }

  private processObservableDashboards(selectedDashboard: DashboardConfig) {
    let featureToggleNames: FeatureToggle[] = [];

    if (this.hasFeatureToggleActivationDashboard(selectedDashboard)) {
      featureToggleNames.push(selectedDashboard["featureToggleActivation"]);
    }

    this.featureToggleService
      .isFeaturesOn(featureToggleNames)
      .subscribe((result) => {
        if (!this.isObservableDashboardEnabled(selectedDashboard, result)) {
          throw new Error("This dashboard is not available.");
        }
      });
  }

  private isObservableDashboardEnabled(
    dashboard: DashboardConfig,
    featureToggleResults: FeatureToggleResponse[],
  ): boolean {
    return (
      this.hasFeatureToggleActivationDashboard(dashboard) &&
      featureToggleResults.some(
        (item) =>
          item.active && item.name === dashboard.featureToggleActivation.name,
      )
    );
  }

  private hasFeatureToggleActivationDashboard(
    dashboard: DashboardConfig,
  ): boolean {
    return dashboard.hasOwnProperty("featureToggleActivation");
  }
}
