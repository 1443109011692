<div class="view-main">
  <div class="wrapper">
    <div
      [ngClass]="
        authService.isEnterprise() != null ? 'view-header' : 'view-header-ds'
      "
      style="align-items: center"
    ></div>

    <div class="dashboard">
      <ng-container *ngIf="tabs.length > 1">
        <nav mat-tab-nav-bar>
          <a
            mat-tab-link
            *ngFor="let tab of tabs"
            [routerLink]="tab.path"
            [active]="tab.path === activeLink"
          >
            {{ tab.label }}
          </a>
        </nav>
      </ng-container>

      <div class="content">
        <div class="progress-widget" *ngIf="progressBarHelper.isVisible">
          <div class="progress-bar">
            <mat-progress-bar
              [mode]="progressBarHelper.progressBarMode"
            ></mat-progress-bar>
          </div>
          <div class="progress-message">
            <p>One moment please, we are loading the data...</p>
          </div>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
