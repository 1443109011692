import { Injectable } from "@angular/core";
import { AuthenticationService } from "../interfaces/AuthenticationService";
import { jwtDecode } from "jwt-decode";

const TOKEN_KEY: string = "jwt";

@Injectable({
  providedIn: "root",
})
export class CcaAuthenticationService implements AuthenticationService {
  getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
  }

  isEnterprise(): boolean {
    const userClaims = this.extractUserClaims(this.getToken());
    if (userClaims) {
      return (
        userClaims.activeAccount.isEnterpriseAccount ||
        userClaims.enterprises.some((e) => {
          return e.enterprise.id === userClaims.activeAccount.id;
        })
      );
    } else {
      return null;
    }
  }

  extractUserClaims(token: string) {
    const decodedToken = this.decodeToken(token);
    if (decodedToken) {
      return JSON.parse(decodedToken.userClaims);
    } else {
      return null;
    }
  }

  getUser(): any {
    const userClaims = this.extractUserClaims(this.getToken());
    if (userClaims) {
      const user = userClaims.user;
      const activeAccount = userClaims.activeAccount;
      return {
        user_id: user.uniqueUserId,
        user_email: user.username,
        user_name: user.fullName,
        account_id: activeAccount.id,
        account_name: activeAccount.name,
        account_enterprise: this.isEnterprise(),
      };
    } else {
      return null;
    }
  }

  decodeToken(token: string): any {
    if (token) {
      try {
        return jwtDecode(token);
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }
}
