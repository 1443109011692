import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LookerDashboardsModule } from "./looker-dashboards/looker-dashboards.module";
import { DashboardComponent } from "./looker-dashboards/components/dashboard/dashboard.component";
import { EnterpriseAccessGuard } from "./guards/enterprise-access.guard";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "multichannel/overview",
  },
  {
    path: "multichannel/overview",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "poppulo_native::multichannel_overview",
      apiVersion: "V1",
    },
  },
  {
    path: "multichannel/content-performance",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "poppulo_native::multichannel_content_performance",
      apiVersion: "V1",
    },
  },
  {
    path: "multichannel/content-insights-themes",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "research::content_insights_themes",
      apiVersion: "V1",
    },
  },
  {
    path: "multichannel/content-insights-readability",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "research::content_insights_readability",
      apiVersion: "V1",
    },
  },
  {
    path: "multichannel/campaigns",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "poppulo_native::multichannel_campaigns",
      apiVersion: "V1",
    },
  },
  {
    path: "email/enterprise-overview",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "poppulo_native::email_enterprise_overview",
      apiVersion: "V1",
    },
  },
  {
    path: "email/account-overview",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "poppulo_native::email_account_overview",
      apiVersion: "V1",
    },
  },
  {
    path: "email/performance",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "poppulo_native::email_performance",
      apiVersion: "V1",
    },
  },
  {
    path: "email/campaigns",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "poppulo_native::multichannel_campaigns",
      apiVersion: "V1",
    },
  },
  {
    path: "email/send-and-open-heatmaps",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "poppulo_native::email_send_and_open_heatmaps",
      apiVersion: "V1",
    },
  },
  {
    path: "mobile/engagement",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "poppulo_native::mobile_engagement",
      apiVersion: "V1",
    },
  },
  {
    path: "mobile/adoptions",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: { dashboardId: "poppulo_native::mobile_adoptions", apiVersion: "V1" },
  },
  {
    path: "mobile/content",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "poppulo_native::mobile_content_performance",
      apiVersion: "V1",
    },
  },
  {
    path: "mobile/campaigns",
    component: DashboardComponent,
    canActivate: [EnterpriseAccessGuard],
    data: {
      dashboardId: "poppulo_native::multichannel_campaigns",
      apiVersion: "V1",
    },
  },
  {
    path: "signage/devices-overview",
    component: DashboardComponent,
    canActivate: [],
    data: {
      dashboardId: "poppulo_native::signage_devices_overview",
      apiVersion: "V2",
    },
  },
  {
    path: "signage/playlist-overview",
    component: DashboardComponent,
    canActivate: [],
    data: {
      dashboardId: "poppulo_native::signage_playlist_overview",
      apiVersion: "V2",
    },
  },
  {
    path: "signage/content-item-overview",
    component: DashboardComponent,
    canActivate: [],
    data: {
      dashboardId: "poppulo_native::signage_content_item_overview",
      apiVersion: "V2",
    },
  },
  {
    path: "signage/app-overview",
    component: DashboardComponent,
    canActivate: [],
    data: {
      dashboardId: "poppulo_native::signage_app_overview",
      apiVersion: "V2",
    },
  },
  {
    path: "signage/channel-overview",
    component: DashboardComponent,
    canActivate: [],
    data: {
      dashboardId: "poppulo_native::signage_channel_overview",
      apiVersion: "V2",
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), LookerDashboardsModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
