import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { LookerService } from "../../../services/looker.service";
import { ProgressBarHelper } from "../../../services/progress-bar-helper.service";
import { ActivatedRoute, Data } from "@angular/router";
import { Subscription } from "rxjs";
import { CcaAuthenticationService } from "../../../services/cca-authentication.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements AfterViewInit, OnDestroy {
  private activeRouteSub: Subscription = null;
  private lookerSub: Subscription = null;

  pageError: boolean = false;

  @ViewChild("iframe") iframe: ElementRef;

  constructor(
    private lookerService: LookerService,
    private progressBarHelper: ProgressBarHelper,
    private activeRoute: ActivatedRoute,
  ) {}

  ngAfterViewInit(): void {
    this.activeRouteSub = this.activeRoute.data.subscribe((d: Data): void => {
      this.lookerSub = this.lookerService
        .getDashboard(this.getDashboardId(d), d.apiVersion)
        .subscribe(
          (resp): void => {
            this.iframe.nativeElement.setAttribute("src", resp.url);
          },
          (): void => {
            this.iframe.nativeElement.setAttribute("src", "about:blank");
            this.pageError = true;
          },
        );
    });
  }

  ngOnDestroy(): void {
    this.activeRouteSub.unsubscribe();
    this.lookerSub.unsubscribe();
  }

  hideProgressBar(iframe: HTMLIFrameElement): void {
    if (
      (iframe.src && iframe.src !== "about:blank") ||
      this.pageError === true
    ) {
      this.progressBarHelper.setVisible(false);
    }
  }

  private getDashboardId(d: Data): string {
    if (d.apiVersion === "V2") {
      return d.dashboardId;
    } else {
      let authService: CcaAuthenticationService =
        new CcaAuthenticationService();
      if (authService.isEnterprise() && d.enterpriseDashboardId) {
        return d.enterpriseDashboardId;
      }
      return d.dashboardId;
    }
  }
}
