/* tslint:disable:quotemark */
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { CcaAuthenticationService } from "./app/services/cca-authentication.service";
import {
  COMPANY_ID_KEY,
  COMPANY_NAME_KEY,
  OktaAuthenticationService,
  TOKEN_ID_KEY,
} from "./app/services/okta-authentication.service";
import { AuthenticationService } from "./app/interfaces/AuthenticationService";
import { SentryMonitoring } from "./app/services/sentry-monitoring.service";

if (environment.production) {
  enableProdMode();
}

if (
  (window.location.href.includes("admin") &&
    window.location.href.includes("notessa")) ||
  window.location.href.includes("localhost")
) {
  localStorage.setItem("targetOrigin", window.location.href);
  const authService: AuthenticationService = new CcaAuthenticationService();
  SentryMonitoring.init(authService.getUser());

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
} else {
  window.addEventListener("message", (event: MessageEvent<any>): void => {
    if (typeof event.data === "string") {
      let eventData = JSON.parse(event.data);
      let accessToken = eventData["jwt"];

      if (accessToken) {
        localStorage.setItem(TOKEN_ID_KEY, accessToken);
        localStorage.setItem(COMPANY_NAME_KEY, eventData[COMPANY_NAME_KEY]);
        localStorage.setItem(COMPANY_ID_KEY, eventData[COMPANY_ID_KEY]);
        localStorage.setItem("targetOrigin", event.origin);
      } else {
        localStorage.setItem("targetOrigin", window.location.href);
      }

      const authService: AuthenticationService =
        new OktaAuthenticationService();

      SentryMonitoring.init(authService.getUser());

      platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
    }
  });
}
