import { FeatureToggle, FeatureToggleKey } from "../model/feature-toggle";

export class NotessaURLHelper {
  public static buildFeatureToggleUrl(featureToggle: FeatureToggle): string {
    return featureToggle.key === FeatureToggleKey.ACCOUNT_ID
      ? `/notessa/api/featureToggle/${featureToggle.name}`
      : "/notessa/api/accounts/" +
          this.getAccountCodeFromCurrentLocation() +
          `/emails/featureToggle/${featureToggle.name}`;
  }

  public static getAccountCodeFromCurrentLocation(): string {
    return this.getAccountCode(window.location.pathname);
  }

  public static getAccountCode(url: string): string {
    let accountCodeTmp = url.replace(/.*accounts\//g, "");
    return accountCodeTmp.substring(0, accountCodeTmp.indexOf("/"));
  }
}
