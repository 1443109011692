import { FeatureToggle, FeatureToggleKey } from "./feature-toggle";

export interface DashboardConfig {
  path: string;
  label: string;
  isEnterpriseDashboard?: boolean;
  tabsGroup?: string;
  featureToggleActivation?: FeatureToggle;
}

export const DASHBOARD_CONFIG: DashboardConfig[] = [
  {
    path: "multichannel/overview",
    label: "Multichannel Overview",
    isEnterpriseDashboard: false,
  },
  {
    path: "multichannel/content-performance",
    label: "Multichannel Content Performance",
    isEnterpriseDashboard: false,
  },
  {
    path: "multichannel/content-insights-themes",
    label: "Themes",
    isEnterpriseDashboard: false,
    tabsGroup: "content-insights",
  },
  {
    path: "multichannel/content-insights-readability",
    label: "Readability",
    isEnterpriseDashboard: false,
  },
  {
    path: "multichannel/campaigns",
    label: "Multichannel Campaigns",
    isEnterpriseDashboard: false,
  },
  {
    path: "email/enterprise-overview",
    label: "Email Enterprise Overview",
    isEnterpriseDashboard: true,
  },
  {
    path: "email/account-overview",
    label: "Email Account Overview",
    isEnterpriseDashboard: false,
  },
  {
    path: "email/performance",
    label: "Email - Performance",
    isEnterpriseDashboard: false,
  },
  {
    path: "email/send-and-open-heatmaps",
    label: "Email - Send and Open Heatmaps",
    isEnterpriseDashboard: false,
  },
  {
    path: "email/campaigns",
    label: "Email Campaigns",
    isEnterpriseDashboard: false,
  },
  {
    path: "mobile/engagement",
    label: "Engagement",
    isEnterpriseDashboard: false,
  },
  {
    path: "mobile/adoptions",
    label: "Adoptions",
    isEnterpriseDashboard: false,
  },
  {
    path: "mobile/content",
    label: "Content Performance",
    isEnterpriseDashboard: false,
  },
  {
    path: "mobile/campaigns",
    label: "Campaigns",
    isEnterpriseDashboard: false,
  },
  {
    path: "signage/devices-overview",
    label: "Signage Devices Overview",
  },
  {
    path: "signage/playlist-overview",
    label: "Signage Playlist Overview",
  },
  {
    path: "signage/content-item-overview",
    label: "Signage Content Item Overview",
  },
  {
    path: "signage/app-overview",
    label: "Signage App Overview",
  },
  {
    path: "signage/channel-overview",
    label: "Signage Channel Overview",
  },
];
