import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { CcaAuthenticationService } from "../services/cca-authentication.service";
import { OktaAuthenticationService } from "../services/okta-authentication.service";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private inj: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf("/v1/") > -1) {
      return next.handle(this.addAuthorizationHeader(request));
    } else if (request.url.indexOf("/v2/") > -1) {
      return next.handle(this.addDSCloudHeaders(request));
    }
  }

  private addAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
    const authService: CcaAuthenticationService = this.inj.get(
      CcaAuthenticationService,
    );
    const token: string = authService.getToken();
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return request;
  }

  private addDSCloudHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const authService: OktaAuthenticationService = this.inj.get(
      OktaAuthenticationService,
    );
    const token: string = authService.getToken();
    const companyId: string = authService.getCompanyId();
    const companyName: string = authService.getCompanyName();
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          "Company-Id": companyId,
          "Company-Name": companyName,
        },
      });
    }
    return request;
  }
}
