<div class="alert" *ngIf="pageError">
  <p class="message error">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i
    ><span
      >Something went wrong. This page didn't load, please refresh the page or
      <a
        href="mailto:support@poppulo.com?subject=Analytics%20iframe%20failed%20to%20load"
        >contact the Poppulo support</a
      >.</span
    >
  </p>
</div>

<iframe #iframe (load)="hideProgressBar(iframe)"></iframe>
